.questions-scroll {
  max-height: 500px;
  overflow-y: auto;
}

.questions-scroll::-webkit-scrollbar {
  width: 8px;
  cursor: pointer, auto;
}

.questions-scroll::-webkit-scrollbar-track {
  background: rgb(226, 226, 226);
  border-radius: 100vw;
  margin-block: 0.5em;
}

.questions-scroll::-webkit-scrollbar-thumb {
  background: rgb(167, 167, 167);

  border-radius: 100vw;
}

.questions-scroll::-webkit-scrollbar-thumb:hover {
  background: #4596ff;
}
