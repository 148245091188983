.boarding-pass-container {
  width: 100%;

  border-radius: 20px;
  border: 1px solid rgba(128, 128, 128, 0.27843);
  background-color: white;
}

.boarding-pass {
  width: 100%;
  padding: 10px 10px;
}

.boarding-pass .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.boarding-pass .hr {
  border-top: 1px solid #80808033;
  margin-top: 10px;
  margin-bottom: 10px;
}

.boarding-pass .first {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.boarding-pass .first .left {
}

.boarding-pass .first .left span {
  font-size: 16px;
  font-weight: 500;
  color: #595959;
}

.boarding-pass .first .left h4 {
  margin: 0;

  font-size: 22px;
  margin-top: 2px;
  color: #343392;
  font-weight: 500;
}

.boarding-pass .first .left p {
  margin: 0;
  color: #343392;
  font-weight: 500;
  font-size: 14px;
  margin-top: -5px;
}

.boarding-pass .first .left p span {
  color: #595959;
  font-size: 14px;
}

.boarding-pass .first .right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.boarding-pass .first .right h4 {
  margin: 0;
  font-weight: 500;
  color: #342f95;
  font-size: 45px;
  margin-right: 3px;
}

.boarding-pass .first .right div p {
  margin: 0;
  font-size: 18px;
  color: #626262;
  font-weight: 500;
  text-transform: uppercase;
}

.boarding-pass .first .right div span {
  display: block;
  margin-top: 2px;
  font-size: 18px;
  color: #626262;
  font-weight: 500;
}

.boarding-pass .journey-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
}

.boarding-pass .journey-details .left {
  text-align: left;
}

.boarding-pass .journey-details .left > span {
  font-size: 10px;
  display: block;
}

.boarding-pass .journey-details .left p {
  margin-top: 3px;
  margin-bottom: 0;
  color: #342f95;
  font-size: 16px;
}

.boarding-pass .journey-details .left p span {
  color: #575757;
}

.boarding-pass .journey-details .right {
  text-align: right;
}

.boarding-pass .journey-details .right > span {
  font-size: 10px;
  display: block;
}

.boarding-pass .journey-details .right p {
  margin-top: 3px;
  margin-bottom: 0;
  color: #342f95;
  font-size: 16px;
}

.boarding-pass .journey-details .right p span {
  color: #575757;
}

.boarding-pass .second {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.boarding-pass .second .left {
  width: 45%;
  text-align: left;
}

.boarding-pass .second .left p {
  margin: 0;

  font-size: 14px;
  color: #575757;
  margin-top: 4px;
}

.boarding-pass .second .left h4 {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  margin-top: 2px;
  color: #2f319e;
}

.boarding-pass .second .left span {
  font-size: 12px;
  font-weight: 500;
  color: #595959;
}

.boarding-pass .second img {
  width: 10%;
}

.boarding-pass .second .right {
  width: 45%;
  text-align: right;
}

.boarding-pass .second .right p {
  margin: 0;
  font-size: 14px;
  color: #575757;
  margin-top: 2px;
}

.boarding-pass .second .right h4 {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  margin-top: 2px;
  color: #2f319e;
}

.boarding-pass .second .right span {
  font-size: 12px;
  font-weight: 500;
  color: #595959;
}

.boarding-pass .third {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}

.boarding-pass .third div {
  text-align: center;
}

.boarding-pass .third div h5 {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  color: #1b1b1b;
}

.boarding-pass .third div p {
  margin: 0;
  font-weight: 600;
  color: #444393;
  margin-top: 4px;
  font-size: 14px;
}

.boarding-pass .third div span {
  color: #626262;
  display: block;
  margin-top: 2px;
  font-size: 14px;
  font-weight: 600;
}

.boarding-pass .fourth {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.boarding-pass .fourth .dist div span {
  display: block;
  font-size: 10px;
  color: #444393;
  font-weight: 700;
}

.boarding-pass .fourth .dist div p {
  margin: 0;
  font-size: 14px;
  margin-top: 3px;
  color: #626262;
}

.boarding-pass .fourth .departure {
  text-align: center;
}

.boarding-pass .fourth .departure span {
  font-size: 14px;
  color: #444393;
  font-weight: 500;
}

.boarding-pass .fourth .departure h5 {
  margin: 0;
  font-size: 14px;
  margin-top: 6px;
  color: #3d3d3d;
  font-weight: 400;
}

.boarding-pass .fourth .departure p {
  margin: 0;
  font-size: 14px;
  margin-top: 2px;
  color: #444393;
}

.boarding-pass .passengers {
}

.boarding-pass .passengers h4 {
  margin: 0;
  font-size: 14px;
  color: #342f95;
  font-weight: 500;
  margin-bottom: 5px;
  padding-bottom: 4px;
}

.boarding-pass .passengers p {
  margin: 0;
  font-size: 12px;
  color: #626262;
  font-weight: 500;
  padding: 5px 0px 5px 0px;
}

.boarding-pass .QR {
  display: block;
  margin: 0px auto;
  width: 110px;
}

.boarding-pass .corover {
  text-align: center;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boarding-pass .corover span {
  color: #f15d00;
  font-weight: 600;
}

.boarding-pass .corover img {
}

.boarding-pass .corover span {
}

.boarding-pass-button {
  width: 100%;
  text-align: center;
  padding: 14px;
  background-color: #0b74fa;
  color: white;
  margin-top: 15px;
  // border-bottom-left-radius: 15px;
  // border-bottom-right-radius: 15px;
}

.boarding-pass-container .buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.boarding-pass-container .buttons div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.boarding-pass-container .buttons div .icon {
  width: 30px;
  height: 30px;
  border-radius: 29%;
  background-color: #0b74fa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boarding-pass-container .buttons div .icon img {
  width: 16px;
}

.boarding-pass-container .buttons div p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: #0b74fa;
  margin-left: 4px;
}

// ////////////////////////////////////////@at-root

.boarding-pass-container2 {
  border-radius: 0px;
  border: 1px solid rgba(128, 128, 128, 0.27843);
  background-color: white;
  width: 21cm;
  height: 29.65cm;
  margin: 0 auto;
  position: relative;
  line-height: 1.2;
}

.boarding-pass2 {
  padding: 10px 40px;
}

.boarding-pass2 .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.boarding-pass2 .hr {
  border-top: 1px solid #80808033;
  margin-top: 0.4cm;
  margin-bottom: 0.4cm;
}

.boarding-pass2 .first {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.boarding-pass2 .first .left {
}

.boarding-pass2 .first .left span {
  font-size: 0.6cm;
  font-weight: 500;
  color: #595959;
}

.boarding-pass2 .first .left h4 {
  margin: 0;
  font-size: 0.8cm;
  margin-top: 4px;
  color: #343392;
  font-weight: 500;
}

.boarding-pass2 .first .right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.boarding-pass2 .first .right h4 {
  margin: 0;
  font-weight: 500;
  color: #342f95;
  font-size: 1.5cm;
  margin-right: 3px;
}

.boarding-pass2 .first .right div p {
  margin: 0;
  font-size: 0.6cm;
  color: #626262;
  font-weight: 500;
  text-transform: uppercase;
}

.boarding-pass2 .first .right div span {
  display: block;
  margin-top: 0px;
  font-size: 0.6cm;
  color: #626262;
  font-weight: 500;
}

.boarding-pass2 .journey-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.boarding-pass2 .journey-details .left {
  text-align: left;
}

.boarding-pass2 .journey-details .left > span {
  font-size: 0.3cm;
  display: block;
}

.boarding-pass2 .journey-details .left p {
  margin-top: 0px;
  margin-bottom: 0;
  color: #342f95;
  font-size: 0.6cm;
}

.boarding-pass2 .journey-details .left p span {
  color: #575757;
}

.boarding-pass2 .journey-details .right {
  text-align: right;
}

.boarding-pass2 .journey-details .right > span {
  font-size: 0.3cm;
  display: block;
}

.boarding-pass2 .journey-details .right p {
  margin-top: 0px;
  margin-bottom: 0;
  color: #342f95;
  font-size: 0.6cm;
}

.boarding-pass2 .journey-details .right p span {
  color: #575757;
}

.boarding-pass2 .second {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.boarding-pass2 .second .left {
  width: 45%;
  text-align: left;
}

.boarding-pass2 .second .left p {
  margin: 0;
  font-size: 0.5cm;
  color: #575757;
  margin-top: 0px;
}

.boarding-pass2 .second .left h4 {
  margin: 0;
  font-size: 1cm;
  font-weight: 500;
  margin-top: 4px;
  color: #2f319e;
}

.boarding-pass2 .second .left span {
  font-size: 0.4cm;
  font-weight: 500;
  color: #595959;
}

.boarding-pass2 .second img {
  width: 10%;
}

.boarding-pass2 .second .right {
  width: 45%;
  text-align: right;
}

.boarding-pass2 .second .right p {
  margin: 0;
  font-size: 0.5cm;
  color: #575757;
  margin-top: 0px;
}

.boarding-pass2 .second .right h4 {
  margin: 0;
  font-size: 1cm;
  font-weight: 500;
  margin-top: 4px;
  color: #2f319e;
}

.boarding-pass2 .second .right span {
  font-size: 0.4cm;
  font-weight: 500;
  color: #595959;
}

.boarding-pass2 .third {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}

.boarding-pass2 .third div {
  text-align: center;
}

.boarding-pass2 .third div h5 {
  margin: 0;
  font-weight: 400;
  font-size: 0.4cm;
  color: #1b1b1b;
}

.boarding-pass2 .third div p {
  margin: 0;
  font-weight: 600;
  color: #444393;
  font-size: 0.7cm;
}

.boarding-pass2 .third div span {
  color: #626262;
  display: block;
  font-size: 0.5cm;
  font-weight: 600;
}

.boarding-pass2 .passengers {
}

.boarding-pass2 .passengers h4 {
  margin: 0;
  font-size: 0.8cm;
  color: #342f95;
  font-weight: 500;
  margin-bottom: 5px;
}

.boarding-pass2 .passengers p {
  margin: 0;
  font-size: 0.6cm;
  color: #626262;
  font-weight: 500;
}

.boarding-pass2 .QR {
  display: block;
  margin: 10px auto;
  // width: 3cm;
  position: absolute;
  bottom: 3cm;
  left: 50%;
  transform: translateX(-50%);
}

.boarding-pass2 .corover {
  text-align: center;
  font-size: 0.6cm;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 2cm;
  left: 50%;
  transform: translateX(-50%);
}

.boarding-pass2 .corover span {
  color: #f15d00;
  font-weight: 600;
}

.boarding-pass2 .corover img {
}

.boarding-pass2 .corover span {
}

.boarding-pass2 .fourth {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.boarding-pass2 .fourth .dist div span {
  display: block;
  font-size: 0.3cm;
  color: #444393;
  font-weight: 700;
}

.boarding-pass2 .fourth .dist div p {
  margin: 0;
  font-size: 0.5cm;
  color: #070505da;
}

.boarding-pass2 .fourth .departure {
  text-align: center;
}

.boarding-pass2 .fourth .departure span {
  font-size: 0.5cm;
  color: #444393;
  font-weight: 500;
}

.boarding-pass2 .fourth .departure h5 {
  margin: 0;
  font-size: 0.6cm;
  color: #3d3d3d;
  font-weight: 400;
}

.boarding-pass2 .fourth .departure p {
  margin: 0;
  font-size: 0.6cm;
  margin-top: 0px;
  color: #444393;
}
