.xy  {
    display: contents !important;
    align-self: stretch !important;
  
  }
  
  .xy span {
    align-self: stretch !important;
  
  }
  