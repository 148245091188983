.textbox {
  border: 1px solid #d8d8d8;
  padding: 10px 10px;
  border-radius: 6px;
  position: relative;
  margin-top: 16px;
  background-color: white;
}

.textbox input {
  font-size: 16px;
  border: none;
  width: 100%;
}

.textbox input:focus {
  border: none;
  outline: none;
}

.textbox p {
  margin: 0;
  font-size: 16px;
  color: #323232;
}

.textbox label {
  position: absolute;
  top: -8px;
  left: 10px;
  font-size: 12px;
  display: block;
  background-color: white;
  padding: 0px 5px;
  color: #323232;
}

.textbox2 {
  position: relative;
  margin-top: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.textbox2 input {
  font-size: 16px;
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  color: #323232 !important;
}

.textbox2 input:focus {
  border: 1px solid #d8d8d8;
  outline: none;
}

.textbox2 label {
  position: absolute;
  top: -6px;
  left: 10px;
  font-size: 12px;
  display: block;
  background-color: white;
  padding: 0px 2px;
  color: #323232;
}
