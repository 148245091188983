@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");

html {
  height: 100%;
  font-size: 100%;
}

:root {
  --animLeft: 0rem;
  --animTime: 0s;
  --showMic: block;
}

body {
  height: 100%;
  margin: 0;

  font-family: "Roboto", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

@media only screen and (min-width: 50rem) {
  body {
    background-color: #0093e9;
    background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  line-height: 1;
}

.pcFrame {
  width: 100% !important;
}

@keyframes floating {
  0% {
    transform: translate(0, 0rem) scale(1.2);
    opacity: 1;
  }
  50% {
    transform: translate(0, 0.3125rem) scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: translate(0, 0rem) scale(1.2);
    opacity: 1;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0rem 62.5rem white inset;
}

*::-webkit-scrollbar {
  width: 0.5rem;
  cursor: pointer, auto;
}

.banner::-webkit-scrollbar {
  width: 0.5rem;
  display: none;
  cursor: pointer, auto;
}

*::-webkit-scrollbar-track {
  background: rgb(226, 226, 226);
  border-radius: 100vw;
  margin-block: 0.2em;
}

*::-webkit-scrollbar-thumb {
  background: rgb(167, 167, 167);

  border-radius: 100vw;
}

*::-webkit-scrollbar-thumb:hover {
  background: #497bb9;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* button {
  box-shadow: rgba(67, 71, 85, 0.27) 0rem 0rem 0.25em,
    rgba(90, 125, 188, 0.05) 0rem 0.25em 1em;
} */

.mobile-datepicker .month ul.date li span {
  color: black;
  font-weight: 500;
}
