.book-form-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #efefef;
  padding: 7px 12px;
  border-radius: 10px;
  margin-bottom: 8px;
  margin-top: 7px;
}

.book-form-input input {
  border: none;
  background-color: transparent;
  width: 100%;
  margin: 0px 8px;
  font-size: 16px;
}

.book-form-input input:focus {
  outline: none;
}

.input {
  margin-bottom: 5px;
}

.input p {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}

.input input {
  font-size: 16px;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #a9a6a6;
  border-radius: 6px;
}

.input input:focus {
  outline: none;
}

.selects-quota {
  padding: 15px;
  padding-bottom: 0px;
}

.selects-quota .quota {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.selects-quota .quota .icon {
  height: 40px;
  width: 40px;
  background-color: rgb(235, 238, 253);
  border-radius: 50%;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.selects-quota .quota .icon img {
  width: 80%;
}

.selects-quota .quota p span {
  margin: 0;
  font-weight: 600;
}
.selects-quota .quota p {
  margin: 0;
  font-size: 15px;
  color: #464444;
}

.selects-quota #general .icon {
  background-color: #cce3f5;
}

.selects-quota #general .icon img {
  width: 80%;
}

.selects-quota #ladies .icon {
  background-color: #f7e4f3;
}

.selects-quota #ladies .icon img {
  width: 55%;
}

.selects-quota #senior .icon {
  background-color: #e5e5e5;
}

.selects-quota #senior .icon img {
  width: 65%;
}

.selects-quota #tatkal .icon {
  background-color: #fff3dc;
}

.selects-quota #tatkal .icon img {
  width: 65%;
}

.scroll-container::-webkit-scrollbar {
  width: 8px;
  cursor: pointer, auto;
}

.scroll-container::-webkit-scrollbar-track {
  background: rgb(226, 226, 226);
  border-radius: 100vw;
  margin-block: 0.5em;
}

.scroll-container::-webkit-scrollbar-thumb {
  background: rgb(167, 167, 167);

  border-radius: 100vw;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #4596ff;
}
