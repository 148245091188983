.input1 {
  width: 100%;

  input,
  textarea {
    width: 100%;
    border: 1px solid darkgray;
    font-size: 16px;
    padding: 10px;
    border-radius: 6px;
    font-family: "Roboto", sans-serif;
    outline: none;
  }
  input {
    margin-top: 10px;
  }
}

.input2 {
  width: 100%;
  position: relative;
  display: inline-block;
  

  input,
  textarea {
    cursor: pointer !important;
    caret-color: transparent;
    width: 100%;
    border: 1px solid darkgray;
    font-size: 16px;
    padding: 10px;
    border-radius: 6px;
    font-family: "Roboto", sans-serif;
    outline: none;
  }
  input {
    margin-top: 10px;
  }
}

.xy  {
  display: contents !important;
  align-self: stretch !important;

}


.input2::before {
  position: absolute;
  content: "▼";
  top: 40%;
  right: 2%;
  height: 20px;
  width: 20px;
  pointer-events: none;
}

.feed-btn {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background: #326abb;
  color: white;
  border-radius: 6px;
  margin-top: 10px;
  cursor: pointer;
  border: none;
}

.feed-btn:disabled,
.feed-btn[disabled] {
  background-color: #e0e0e0;
  color: #bcbcbc;
  cursor: not-allowed;
}
