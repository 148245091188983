.searchbox {
  position: relative;
  margin-top: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 90%;
  margin-bottom: 10px;
}

.searchbox input {
  font-size: 18px;
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  color: #323232 !important;
}

.searchbox input:focus {
  border: 1px solid #d8d8d8;
  outline: none;
}

.country {
  font-size: 18px;
  padding: 8px;
  margin: 0 auto;
  width: 95%;
  text-align: center;
  border-bottom: 1px solid #ecececba;
  cursor: pointer;
}

.country:hover {
  background-color: aliceblue;
}

.country:last-child {
  border-bottom: none;
}

.container::-webkit-scrollbar {
  width: 8px;
  cursor: pointer, auto;
}

.container::-webkit-scrollbar-track {
  background: rgb(226, 226, 226);
  border-radius: 100vw;
  margin-block: 0.5em;
}

.container::-webkit-scrollbar-thumb {
  background: rgb(167, 167, 167);

  border-radius: 100vw;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #4596ff;
}
