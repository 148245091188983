.icons-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  row-gap: 10px;
  margin-top: 15px;
  column-gap: 20px;
}

.icon-white {
  display: flex;
  min-width: 70px;
  max-width: 70px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
}

.icon-white img {
  width: 40%;
}

.icon-white p {
  margin-top: 5px;
  font-size: 10px;
  line-height: 1.2;
  color: #232323;
  font-weight: 700;
  text-align: center;
}

.icon-white:hover {
  transform: scale(1.1);
}
