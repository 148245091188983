.final-success2 {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.final-success2 h1 {
  color: dimgray;
  font-size: 18px;
  margin-top: 14px;
}

@keyframes fade-in {
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.final-success2 img {
  width: 220px;
}

.final-success2 span {
  margin: 0;
  color: #00b213;
  margin-top: 0.625rem;
  font-weight: 500;
}

.final-success2 p {
  margin: 0;
  color: #585d61ba;
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 6px;
}

.final-success2 h4 {
  margin: 0;
  color: #0f222e;
  font-weight: 500;
  font-size: 1.25rem;
  margin-top: 0.875rem;
}

.final-success2 h5 {
  margin: 0;
  color: #63686c91;
  font-size: 0.75rem;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 10px;
}
