.logo-container {
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  height: 3.125rem;
  justify-content: center;
  padding: 7px;
  width: 3.125rem;
}

.logo-container img {
  width: 85%;
}
