#feedback-text {
  width: 100%;
  height: 75px;
  border: 1px solid #adadad;
  border-radius: 10px;
  font-size: 14px;
  color: gray;
  padding: 3px 7px;
  outline: none;
}

#feedback-submit {
  border: 1px solid rgb(11, 116, 250);
  background: rgb(11, 116, 250);
  color: white;
  border-radius: 8px;
  font-size: 14px;
  padding: 5px 8px;
  cursor: pointer;
}

#feedback-submit:disabled,
#feedback-submit[disabled] {
  background-color: #e0e0e0;
  color: #bcbcbc;
  cursor: not-allowed;
  border: 1px solid #e0e0e0;
}
